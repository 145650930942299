import React, { useContext, useRef } from 'react';
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { Col, Container, Nav, Navbar, NavDropdown, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap'
import { AuthContext } from '../App';
import Authservice from '../Service/Authservice';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Header = () => {
    const navigate = useNavigate()
    let location = useLocation();
    const Forecasting = useRef(null);
    const MyAccount = useRef(null);
    const Analysis = useRef(null);
    const Trading = useRef(null);
    const ToggleRef = useRef(null);
    const { state, dispatch } = useContext(AuthContext);
    // // Sticky Menu Area
    // useEffect(() => {
    //     window.addEventListener('scroll', isSticky);
    //     return () => {
    //         window.removeEventListener('scroll', isSticky);
    //     };
    // });


    // /* Method that will fix header after a specific scrollable */
    // const isSticky = (e) => {
    //     const header = document.querySelector('.header');
    //     const scrollTop = window.scrollY;
    //     scrollTop >= 300 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
    // };

    const logout = () => {
        dispatch({ type: "LOGOUTPROCESS", payload: true })
        Authservice.Logout()
            .then(res => {
                if (res.data.Success) {
                    toast.success(res.data);
                    dispatch({ type: "LOGOUT", payload: false })
                    dispatch({ type: "LOGOUTPROCESS", payload: false })
                    navigate('/')
                }
                else {
                    toast.error(res.data.Message);
                    dispatch({ type: "LOGOUT", payload: false })
                    dispatch({ type: "LOGOUTPROCESS", payload: false })
                }

            })
            .catch(err => {
                toast.error(err);
                if (err.response.status === 401 || err.response.status === 403) {
                    navigate('/')
                    toast.error(err.response.data.Msg);
                }
                dispatch({ type: "LOGOUT", payload: false })
                dispatch({ type: "LOGOUTPROCESS", payload: false })
            });

    }
    return (
        <>
            {/* <ToastContainer
                position="bottom-right"
                autoClose={7000} /> */}
            <header className='header'>

                <div className='top_header'>
                    <Container>
                        <Row className='justify-content-end'>
                            <Col lg={6}>
                                <ul>
                                    {
                                        state.isAuthenticated ?
                                            <>
                                               <li>
                                                    <Nav.Link >Welcome {state?.user?.First_Name}</Nav.Link>
                                                </li>

{/*                                                 <li>
                                                    <NavDropdown className='top-nav-dropdown' ref={MyAccount} onClick={() => ToggleRef.current.classList.contains("collapsed") ? null : ToggleRef.current.click()} title={<div style={{ display: "inline-block" }}  >
                                                        Welcome {state?.user?.First_Name} </div>}>
                                                        <Nav.Item>
                                                            <NavLink to={`/profile`} className={'dropdown-item'}
                                                                onClick={() => { MyAccount.current.click() }}>
                                                                My Account
                                                            </NavLink>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <NavLink to={`/my_subscriptions`} className={'dropdown-item'}
                                                                onClick={() => { MyAccount.current.click() }} >
                                                                Subscriptions History
                                                            </NavLink>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <NavLink to={`/change_password`} className={'dropdown-item'}
                                                                onClick={() => { MyAccount.current.click() }}>
                                                                Change password
                                                            </NavLink>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <NavLink to={`/my_wallet`} className={'dropdown-item'}
                                                                onClick={() => { MyAccount.current.click() }}>
                                                                Wallet
                                                            </NavLink>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <NavLink to={`/all_tranasactions`} className={'dropdown-item'}
                                                                onClick={() => { MyAccount.current.click() }}>
                                                                All Transactions
                                                            </NavLink>
                                                        </Nav.Item>

                                                    </NavDropdown>
                                                </li> */}

                                                <li>
                                                    <Nav.Link onClick={() => logout()}>Logout</Nav.Link>
                                                </li>
                                            </>
                                            :
                                            <>
                                                <li>
                                                    <LinkContainer to="/sign-up" >
                                                        <Nav.Link  >Sign Up</Nav.Link>
                                                    </LinkContainer>
                                                </li>
                                                <li>
                                                    <LinkContainer to="/sign-in">
                                                        <Nav.Link  >Sign In</Nav.Link>
                                                    </LinkContainer>
                                                </li></>
                                    }

                                </ul>

                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className='main_header'>
                    <Container>
                        <Navbar expand="lg" collapseOnSelect={true}>
                            <LinkContainer to="/">
                                <Navbar.Brand >
                                    <img
                                        src="/images/logo_final.jpg"
                                    />
                                    <p>Exploring&nbsp;Wealth&nbsp;Engines&nbsp;with&nbsp;AI</p>
                                </Navbar.Brand>
                            </LinkContainer>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" ref={ToggleRef} />
                            <Navbar.Collapse id="basic-navbar-nav" className='mobile_navcollapse' >
                                <Nav className="ml-auto">
                                    <NavLink to="/" className={'nav-link'} eventkey="1" onClick={() => ToggleRef.current.click()}>
                                        Home
                                    </NavLink>

                                    <NavDropdown title={<div style={{ display: "inline-block" }}
                                        ref={Forecasting}
                                    >
                                        Forecasting <img src='/images/chevron-down.svg' alt='#' /></div>} id="basic-nav-dropdown"

                                    >
                                        <NavLink to="/russells-stocks" className={'dropdown-item'}
                                            onClick={() => { Forecasting.current.click(); ToggleRef.current.click() }} >
                                            Most Predictable Russell 3000 Forecasts
                                        </NavLink>
                                        <NavLink to="/top-picks-for-day-trade" className={'dropdown-item'}
                                            onClick={() => { Forecasting.current.click(); ToggleRef.current.click() }} eventkey="3">
                                            Top 50 Most Predictable Stocks Forecasts
                                        </NavLink>
                                        {/* <NavLink to="/five-days" className={'dropdown-item'} onClick={() => { Forecasting.current.click(); ToggleRef.current.click() }} eventkey="4">
                                            Most Predictable Next 5 Days' Stocks Forecasts
                                        </NavLink> 
                                        <NavLink to="/quarterly-forecast" className={'dropdown-item'} onClick={() => { Forecasting.current.click(); ToggleRef.current.click() }} eventkey="5">
                                            Quarterly Stocks Forecasts
                                        </NavLink>*/}
                                        <NavLink to="/commodities" className={'dropdown-item'} onClick={() => { Forecasting.current.click(); ToggleRef.current.click() }} eventkey="6">
                                            Commodity & ETF Trends
                                        </NavLink>

                                    </NavDropdown>

                                    <NavDropdown title={<div style={{ display: "inline-block" }} ref={Analysis}>Analysis <img src='/images/chevron-down.svg' alt='#' /></div>} id="basic-nav-dropdown">
                                        {/* <NavLink to="/SnP1500Dashboard " className={'dropdown-item'} onClick={() => { Analysis.current.click(); ToggleRef.current.click() }}>
                                            S&P 1500 Dashboard (including Projections)
                                        </NavLink>
                                        <NavLink to="/critical-fundamentals-quarterly" className={'dropdown-item'}
                                            onClick={() => { Analysis.current.click(); ToggleRef.current.click() }}>
                                            Critical Quarterly Factors - US Stocks
                                        </NavLink>
                                        <NavLink to="/critical-fundamentals-quarterly-world-stocks" className={'dropdown-item'}
                                            onClick={() => { Analysis.current.click(); ToggleRef.current.click() }}>
                                            Critical Quarterly Factors - World Stocks
                                        </NavLink> */}
                                        <NavLink to="/critical-fundamentals-weekly" className={'dropdown-item'}
                                            onClick={() => { Analysis.current.click(); ToggleRef.current.click() }}>
                                            Critical Weekly Technical Factors
                                        </NavLink>
                                        <NavLink to="/snp-highest-margin-safety" className={'dropdown-item'}
                                            onClick={() => { Analysis.current.click(); ToggleRef.current.click() }}>
                                            S&amp;P 1500 Highest Margin of Safety (TTM)
                                        </NavLink>
                                        <NavLink to="/snp-highest-momentum" className={'dropdown-item'}
                                            onClick={() => { Analysis.current.click(); ToggleRef.current.click() }}>
                                            S&amp;P 1500 Highest Momentum
                                        </NavLink>
                                        <NavLink to="/snp-highest-revenue-growth" className={'dropdown-item'}
                                            onClick={() => { Analysis.current.click(); ToggleRef.current.click() }}>
                                            S&amp;P 1500 Highest Revenue Growth
                                        </NavLink>
                                        <NavLink to="/snp-highest-value-creation" className={'dropdown-item'}
                                            onClick={() => { Analysis.current.click(); ToggleRef.current.click() }}>
                                            S&amp;P 1500 Highest Value Creation (TTM)
                                        </NavLink>
                                        <NavLink to="/pennystock" className={'dropdown-item'} onClick={() => { Analysis.current.click(); ToggleRef.current.click() }}>
                                            Penny Stock Suggestions for Day-Trade
                                        </NavLink>
                                        <NavLink to="/intra-volatility" className={'dropdown-item'} onClick={() => { Analysis.current.click(); ToggleRef.current.click() }}>
                                            Most Profitable Stocks for Day-Trade
                                        </NavLink>
                                    </NavDropdown>

                                    <NavDropdown title={<div style={{ display: "inline-block" }} ref={Trading}>Trading <img src='/images/chevron-down.svg' alt='#' /></div>} id="basic-nav-dropdown" className='last_dropdown'>
                                        <NavLink to="/value-based-dynamic-portfolio" className={'dropdown-item'}
                                            onClick={() => { Trading.current.click(); ToggleRef.current.click() }}>
                                            Value-based Dynamic Portfolio
                                        </NavLink>
                                        <NavLink to="/momentum-based-dynamic-portfolio" className={'dropdown-item'}
                                            onClick={() => { Trading.current.click(); ToggleRef.current.click() }}>
                                            Momentum-based Dynamic Portfolio
                                        </NavLink>
                                        <NavLink to="/inovation-stocks-dynamic-portfolio" className={'dropdown-item'}
                                            onClick={() => { Trading.current.click(); ToggleRef.current.click() }}>
                                            Innovation Stocks Dynamic Portfolio
                                        </NavLink>

                                        <NavLink to={{
                                            pathname: "/inovation-stocks-dynamic-portfolio-volatility"
                                        }} className={'dropdown-item'}
                                            onClick={() => { Trading.current.click(); ToggleRef.current.click() }}>
                                            Innovation Stocks Dynamic Portfolio with Volatility Target
                                        </NavLink>

                                        <NavLink to="/small-cap-portfolio" className={'dropdown-item'}
                                            onClick={() => { Trading.current.click(); ToggleRef.current.click() }}  >
                                            Small Cap Dynamic Portfolio
                                        </NavLink>

                                        <NavLink to={{
                                            pathname: "/small-cap-portfolio-volatility"
                                        }} className={'dropdown-item'}
                                            onClick={() => { Trading.current.click(); ToggleRef.current.click() }} >
                                            Small Cap Dynamic Portfolio with Volatility Target
                                        </NavLink>

                                    </NavDropdown>
{/*                                     <NavLink to="/subscriptions" className='nav-link' onClick={() => ToggleRef.current.click()}>
                                        Subscriptions
                                    </NavLink> */}
                                    <NavLink to={{
                                        pathname: "/",
                                        search: "?sec=about",
                                    }} className='no_active nav-link about-menu' onClick={() => ToggleRef.current.click()}>
                                        About Us
                                    </NavLink>

                                    <NavLink to="https://wealthengine.ai/" target='_blank' className='nav-link blog-nav nav-btn no_active'>
                                        Blog
                                    </NavLink>

                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                    </Container>
                </div>
            </header>

        </>
    )


}


export default Header;